<template>
  <div class="customsd">
    <iframe
      id="frame_content"
      width="100%"
      :src="href"
      frameborder="0"
      height="100%"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      href: null,
    };
  },
  async created() {
    const id = Number(localStorage.getItem("WebCid"));
    // 获取域名落地页面地址设置
    let pages = await this.$axios.get("/setting/public/index_page");
    let params = null;
    let url = "";
    if (id == 0) {
      if (pages.data.url) {
        let urls = pages.data.url;
        if (/^www/.test(urls)) {
          window.location.href = "https://" + urls;
        } else if (/^https/.test(urls)) {
          window.location.href = urls;
        } else if (/^\//.test(urls)) {
          this.$router.push(urls);
        }
      } else {
        params = {
          cid: localStorage.getItem("FeiWinCid") || 1,
          //classify: 2, // 平台首页
        };
        url = "/apaas/page/platform/default";
      }
    } else {
      params = {
        cid: id,
        //classify: 3, // 官网首页
      };
      url = "/apaas/page/home/default";
    }
    this.$axios.postJSON(url, params).then((res) => {
      if (res.data.page_id) {
        this.href = "/_apass/lookcustomV3?id=" + res.data.page_id;
      } else {
        setTimeout(() => {
          this.$router.push("/login");
        }, 0);
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customsd {
  height: 100vh;
}
</style>